import ModalController from "./modal_controller";

export default class extends ModalController {
  show(event) {
    console.log();
    const reflexClass = event.target.dataset.reflexClass;
    const reflexMethod = event.target.dataset.reflexMethod;

    console.log(reflexClass + "#" + reflexMethod);
    console.log(event.target.dataset.modalId);

    super.show(event);
    this.stimulate(reflexClass + "#" + reflexMethod, event.target);
  }

  submit(event) {
    event.preventDefault();
    const reflexClass = event.target.dataset.reflexClass;
    const reflexMethod = event.target.dataset.reflexMethod;
    const form = event.srcElement.closest("form");
    const spinnerDiv = form.querySelector("#spinner");
    this.spinnerOn(spinnerDiv);

    console.log(reflexClass + "#" + reflexMethod);

    this.stimulate(reflexClass + "#" + reflexMethod, event.target).then(() => {
      this.spinnerOff(spinnerDiv);
      this.closeModal();
    });
  }
}
