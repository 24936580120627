import ModalController from './../modal_controller';
import flatpickr from 'flatpickr';

export default class extends ModalController {
  static targets = ["paymentFor"];

  showCreateInvoice(event) {
    super.show(event);

    this.stimulate(
      'Billing::InvoiceReflex#show_create_invoice',
      event.target
    ).then(payload => {
      Array.from(document.querySelectorAll('input[name^="due_date"]')).forEach(input => this.assingFlatpickr(input, 'setDueAt'));
      Array.from(document.querySelectorAll('input[name^="paid_at"]')).forEach(input => this.assingFlatpickr(input, 'setPaidAt'));
    });
  }

  updateInvoice(event) {
    let form = event.srcElement.closest('form');
    let spinnerDiv = form.querySelector('#spinner');
    this.spinnerOn(spinnerDiv);
    let paymentForSelector = form.querySelector('#billing_invoice_payment_for');
    this.stimulate(
      'Billing::InvoiceReflex#update_invoice',
      event.target,
      paymentForSelector.value,
      paymentForSelector.querySelector('option[value="' + paymentForSelector.value + '"]').dataset.paymentForType,
      form.querySelector('#billing_invoice_invoice_type').value,
      form.querySelector('#billing_invoice_invoice_id').value,
      form.querySelector('#due_date').value,
      form.querySelector('#billing_invoice_price').value,
      form.querySelector('#billing_invoice_vat').value,
      form.querySelector('#billing_invoice_currency').value,
      form.querySelector('#paid_at').value
    ).then(_payload => {
      document.querySelector('#invoices').scrollIntoView();
    });
  }

  showEditInvoiceModal(event) {
    super.show(event);

    this.stimulate(
      'Billing::InvoiceReflex#show_edit_invoice',
      event.target
    ).then(payload => {
      Array.from(document.querySelectorAll('input[name^="due_date"]')).forEach(input => this.assingFlatpickr(input, 'setDueAt'));
      Array.from(document.querySelectorAll('input[name^="paid_at"]')).forEach(input => this.assingFlatpickr(input, 'setPaidAt'));
    });
  }

  setDueAt(_selectedDates, dateStr, flatpickr_instance) {
    event.preventDefault();
    this.stimulate('Invoice#set_due_at', flatpickr_instance.element, flatpickr_instance.element.dataset.id, dateStr).then(payload => { this.flatpickrToAllDeliveryForms() });
  }

  setPaidAt(_selectedDates, dateStr, flatpickr_instance) {
    event.preventDefault();
    this.stimulate('Invoice#set_paid_at', flatpickr_instance.element, flatpickr_instance.element.dataset.id, dateStr).then(payload => { this.flatpickrToAllDeliveryForms() });
  }

  destroyInvoice(event) {
    let form = event.srcElement.closest('form');
    let spinnerDiv = form.querySelector('#spinner');
    this.spinnerOn(spinnerDiv);
    this.stimulate(
      'Billing::InvoiceReflex#destroy_invoice',
      event.target
    ).then(_payload => {
      document.querySelector('#invoices').scrollIntoView();
    });
  }

  showDestroyInvoiceModal(event) {
    super.show(event);

    this.stimulate(
      'Billing::InvoiceReflex#show_destroy_invoice',
      event.target
    ).then(_payload => {
      location.reload();
    });
  }

  assingFlatpickr(input) {
    var controller = this;
    flatpickr(input, {
      locale: this.getLocale(),
      dateFormat: 'd.m.Y',
    });
  }

  createInvoice(event) {
    let form = event.srcElement.closest('form');
    let spinnerDiv = form.querySelector('#spinner');
    this.spinnerOn(spinnerDiv);
    let paymentForSelector = form.querySelector('#billing_invoice_payment_for');
    this.stimulate(
      'Billing::InvoiceReflex#create_invoice',
      event.target,
      paymentForSelector.value,
      paymentForSelector.querySelector('option[value="' + paymentForSelector.value + '"]').dataset.paymentForType,
      form.querySelector('#billing_invoice_invoice_type').value,
      form.querySelector('#billing_invoice_invoice_id').value,
      form.querySelector('#due_date').value,
      form.querySelector('#billing_invoice_price').value,
      form.querySelector('#billing_invoice_vat').value,
      form.querySelector('#billing_invoice_currency').value,
      form.querySelector('#paid_at').value
    ).then(_payload => {
      document.querySelector('#invoices').scrollIntoView();
    });
  }

  changePaymentFor(event) {
    event.preventDefault();
    this.stimulate(
      'Billing::InvoiceReflex#show_create_invoice',
      event.target,
      this.paymentForTarget.value,
      this.paymentForTarget.querySelector('option[value="' + this.paymentForTarget.value + '"]').dataset.paymentForType,
    )
  }
}
