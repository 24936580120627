import ApplicationController from './application_controller';

export default class extends ApplicationController {

  initialize() {
    document.addEventListener("keydown", evt => {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
          isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
          isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
          this.closeModal();
        }
    });
  }

  closeModal(){
    var modalDialog = document.querySelector('.modal');
    if (modalDialog){
      modalDialog.remove();
    }
  }


  show(event) {
    event.preventDefault();

    var modal = document.createElement('div');
    modal.id = event.currentTarget.dataset.modalId;
    modal.classList.add('modal');
    modal.innerHTML = '<div class="modal-dialog"><div class="spinner-border"></div></div>';

    document.body.appendChild(modal);
  }

  close(event) {
    event.preventDefault();
    event.currentTarget.closest('.modal').remove();
  }
}
